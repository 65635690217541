$primary: #4782da;
$body-bg: #233044;
$bg-color: #233044;
$base-bg: #233044;

// $secondary: #b39ddb;
// $error: #b71c1c;

// $button-padding-x: 100px;
// $button-padding-y: 8px;

// $enable-rounded: false;
// $white: #ffffff;
// $input-color: $white;
// $input-text: $white;
// $input-focused-text: $white;
// $input-focused-bg: yellow;
// $input-bg: yellow;
$textbox-color: #ffffff;
// $floating-label-color: #ffffff;
$floating-label-text: #ffffff;
$dropdownlist-bg: #233044;
$dropdownlist-text: #ffffff;
$dropdownlist-focused-bg: #233044;
$dropdownlist-focused-text: #ffffff;
// $textbox-text: #ffffff;

$drawer-bg: #233044;
$drawer-text: white !default;
$drawer-border: #233044;

$drawer-hovered-bg: #202c3f; //rgb(36, 48, 66) !default;
$drawer-hovered-text: white !default;

$drawer-selected-bg: #1e293a;
$drawer-selected-text: white;
$drawer-selected-hover-bg: #1e293a;
$drawer-selected-hover-text: white;

// $hovered-bg: rgb(35, 48, 68) !default;
// $hovered-text: white;
// $selected-bg: rgb(30, 41, 58);
// $selected-text: white;

// $drawer-hovered-bg: rgb(36, 48, 66) !default;
// $drawer-hovered-text: white !default;

// $drawer-selected-bg: $selected-bg !default;
// $drawer-selected-text: $selected-text !default;
// $drawer-selected-hover-bg: try-shade($selected-bg, 1) !default;
// $drawer-selected-hover-text: $selected-text !default;

$appbar-light-bg: #24292e !default;
$appbar-light-text: #ffffff !default;

$appbar-dark-bg: #24292e !default;
$appbar-dark-text: rgba(#ffffff, 0.87) !default;

@import "~@progress/kendo-theme-bootstrap/scss/_variables.scss";
@import "~@progress/kendo-theme-bootstrap/scss/functions/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/mixins/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/button/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/input/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/numerictextbox/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/dropdownlist/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/textbox/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/avatar/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/floating-label/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/textbox/_layout.scss";

@import "~@progress/kendo-theme-bootstrap/scss/dialog/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/popup/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/progressbar/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/ripple/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/loader/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/appbar/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/drawer/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/icons/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/grid/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/dropdownlist/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/datetime/_index.scss";

@import "~@progress/kendo-theme-bootstrap/scss/forms/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/checkbox/_index.scss";
@import "~@progress/kendo-theme-bootstrap/scss/common/_index.scss";
