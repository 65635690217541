html,
body {
  font-family: open sans, sans-serif;
  font-size: 14px;
  height: 100%;
  /* line-height: 1.42857143; */
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #233044;
}

/* #root {
  height: 100vh;
  
} */
/* #componentContainer {  
  position: absolute;
  top: 0px;
  bottom: 0px;  
  background-color: #1e293a;
} */
.drawer {
  position: absolute;
  height: 95%;
  top: 48px; /* Header Height */
  bottom: 0px;
  width: 100%;
  background-color: #1e293a;
}
/* .k-drawer-push .k-drawer {
  height: 100vh;
} */
p[role="alert"] {
  display: inline;
  margin-left: 10px;
  color: red;
}

div[role="alert"] {
  color: red;
}

.login-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* flex-wrap: wrap; */
  /* flex: 1; */
  background-repeat: round;
  /* background-color: #233044; */
}
.logo {
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* flex-wrap: wrap; */
  /* flex: 1; */
  background-repeat: round;
}
.heading {
  height: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: center;
  font-weight: bolder;
  color: black;
}
.form {
  height: 180px;
  align-self: center;
  width: 250px;
}
.controlDiv {
  margin-top: 20px;
  margin-left: 20px;
}
.alignCenter {
  align-self: flex-center;
}
.home-container {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
