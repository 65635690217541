.referralsContainer {
  /* height: 100vh; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1e293a;
  /* margin: 10px; */
}
